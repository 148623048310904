import React from 'react'
import './footer.css'

export default class footer extends React.Component{
    render(){
        return(
            <div className="footer">
                <div className="content">
                    <div className="leftView">
                        <div className="companyOptions">
                            <img src={require("../../../assets/images/public/foot_logo.png")}></img>
                            <ul>
                                <li>首页</li>
                                <li>新闻资讯</li>
                                <li>关于我们</li>
                                <li>APP下载</li>
                                <li>证书查询</li>
                            </ul>
                        </div>
                        <div className="companyPresentation">
                            <div>
                                <p>地址：深圳市南山区西丽同乐路1002号留仙文化园三栋3层</p>
                                <p>Copyright ©深圳市伍壹叁教育科技有限公司 版权所有 <a>粤ICP备19029030号-1</a></p>
                            </div>
                            <div>
                                <img src={require("../../../assets/images/public/footer_icon_phone.png")}></img>
                                <p>400-8888-513</p>
                            </div>
                        </div>
                    </div>
                    <div className="rightView">
                        <div>
                            <img src={require("../../../assets/images/public/qr_code_wechat.png")}></img>
                            <p>513官方微信</p>
                        </div>
                        <div>
                            <img src={require("../../../assets/images/public/qr_code_app.png")}></img>
                            <p>扫码下载无忧上APP</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}